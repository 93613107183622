'use client'

import { useContext, useEffect, useState } from 'react'
import { RootContext } from '@/modules/contextService'
// import { RootProps } from '@/app/layout'
import { usePathname } from 'next/navigation'
import ThemeClient from '@/app/v1/ThemeClient'
import MainFooter from '@/components/MainFooter'
import HomeHeader from '@/components/display/HomeHeader'
import styles from '@/app/self/styles.module.scss'
import { MINISTRY } from '@/modules/stringService'
import cookieService from '@/modules/cookieService'

// import HomePage1 from '@/components/pages/HomePage_01'
import HomePage2 from '@/components/pages/HomePage_02'

function Page () {
  const { rootData, setRootData } = useContext(RootContext)
  const { user } = rootData
  const [selectedTheme, setSelectedTheme] = useState('')
  const pathname = usePathname()

  useEffect(() => {
    document.title = MINISTRY

    const storedTheme = cookieService.getCookie('theme')

    if (!storedTheme) {
      cookieService.setCookie('theme', storedTheme || 'dark')
    }
    if (pathname !== '/') {
      document.documentElement.setAttribute('data-theme', storedTheme || 'dark')
      setRootData({
        ...rootData,
        theme: storedTheme || 'dark'
      })
      setSelectedTheme(storedTheme || 'dark')
    } else {
      document.documentElement.setAttribute('data-theme', 'dark')
      setRootData({
        ...rootData,
        theme: 'dark'
      })
      setSelectedTheme('dark')
    }
    document.documentElement.setAttribute('data-theme', 'dark')
  }, [])

  return (
    <section>
      <style>{'body { background-color: var(--background-color); color: var(--primary-font-rgb); }'}</style>
      <ThemeClient theme={selectedTheme}>
        <div className={styles.main}>
          <HomeHeader user={user} wide />
          <div className={styles.content}>
            <HomePage2 />
          </div>
          <MainFooter />
        </div>
      </ThemeClient>
    </section>
  )
}

export default Page
