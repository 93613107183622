import MainSection from './MainSection'
// import NextSection from './NextSection'

function HomePage2 () {
  return (
    <div>
      <MainSection />
      {/* <NextSection /> */}
    </div>
  )
}

export default HomePage2
