import Link from 'next/link'
import Image from 'next/image'
import Button from '@/components/inputs/Button'
import { IUser } from '@/interfaces/IUser'
import styles from './HomeHeader.module.scss'
import Sidebar from './Sidebar'
import linkList from './Sidebar/linkList'

function HomeHeader ({ user, wide = true }: { user: IUser | null | undefined, wide: boolean }) {
  return (
    <div className={styles.outerWrapper}>
      <div className={`${styles.innerWrapper} ${wide ? styles.innerWrapperBig : styles.innerWrapperSmall}`}>
        <div className={styles.leftSection}>
          <div className={styles.logo}>
            <Link scroll href='/feed'>
              <Image
                src={'/img/sslm-light-icon.svg'}
                alt="Stepping Stones International Ministry Logo"
                width={50}
                height={50}
                priority
              />
            </Link>
          </div>
          <Link scroll href='/feed' className={styles.logoName}>
            Stepping Stones
          </Link>
        </div>

        <div className={styles.rightSection}>
          <div className={styles.buttonBar}>
            {linkList(user).map((link, i) => {
              return (
                <Button
                  key={i}
                  value={link.value}
                  color={link.color}
                  gutterRight
                  variant={link.variant}
                  linkType='internal'
                  link={link.link}
                  className={styles.hideWhenSmall}
                  style={link.variant === 'contained' ? { paddingLeft: 30, paddingRight: 30, borderRadius: 24 } : undefined}
                />
              )
            })}
          </div>
        </div>
        <div className={styles.modalButton}>
          <div className={styles.buttonBar}>
            <Sidebar user={user} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeHeader
