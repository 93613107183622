import React from 'react'
import Christ from '@/components/home/Banner/christ.json'
import styles from './MainSection.module.scss'
import Button from '@mui/material/Button'
import NextLink from 'next/link'
import TrendingFlatOutlinedIcon from '@mui/icons-material/TrendingFlatOutlined'
import Image from 'next/image'
import StonesImage from '@/components/home/ClickThrough/rocks.webp'
import { Player } from '@lottiefiles/react-lottie-player'

function MainSection () {
  return (
    <div className={styles.fullWidthWrapper}>
      <div className={styles.imageWrapper}>
        <Image
          src={StonesImage}
          alt="Stones Background"
          fill
          priority
          className={styles.bgImage}
        />
      </div>

      <div className={styles.wrapper}>
        <div className={styles.left}>
          <h1 className={styles.title}>From Basic Foundations to Mature Faith</h1>
          <div className={styles.callToAction}>
            <NextLink href='/feed'>
              <Button
                variant='outlined'
                color='primary'
                size='large'
                style={{
                  textTransform: 'none',
                  borderRadius: 20
                }}
                endIcon={<TrendingFlatOutlinedIcon />}
              >
                Enter the Library
              </Button>
            </NextLink>
            <div
              className={styles.subLinks}
            >
              <NextLink href='/what-is-the-gospel'>
                What is the Gospel?
              </NextLink>
              <span>&bull;</span>
              <NextLink href='/where-do-i-begin-now-that-i-m-a-christian'>
                {'Where do I begin now that I\'m a Christian?'}
              </NextLink>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <Player
            src={Christ}
            className={styles.lottie}
            autoplay
            loop
            controls
          />
        </div>
      </div>
    </div>
  )
}

export default MainSection
