import { IUser } from '@/interfaces/IUser'
import InfoIcon from '@mui/icons-material/Info'
import ExploreIcon from '@mui/icons-material/Explore'
import LoginIcon from '@mui/icons-material/Login'
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import { JSX } from 'react'

export interface LinkListObject {
  value: string
  link: string
  color: 'primary' | 'secondary' | undefined
  variant: 'text' | 'contained' | 'outlined' | undefined
  icon: JSX.Element
}
function linkList (user: IUser | null | undefined): LinkListObject[] {
  const linkList: LinkListObject[] = []

  linkList.push({
    value: 'About',
    link: '/about',
    color: 'primary',
    variant: 'text',
    icon: <InfoIcon />
  })
  linkList.push({
    value: 'Explore',
    link: '/topics',
    color: 'primary',
    variant: 'text',
    icon: <ExploreIcon />
  })

  !user && linkList.push({
    value: 'Log In',
    link: '/v1/users/login',
    color: 'primary',
    variant: 'text',
    icon: <LoginIcon />
  })

  !user && linkList.push({
    value: 'Sign Up',
    link: '/v1/users/signup',
    color: 'primary',
    variant: 'text',
    icon: <SensorOccupiedIcon />
  })

  user && linkList.push({
    value: 'Writer Guidelines',
    link: '/content-suggestions',
    color: 'primary',
    variant: 'text',
    icon: <DesignServicesIcon />
  })

  linkList.push({
    value: 'Start Reading',
    link: '/feed',
    color: 'primary',
    variant: 'contained',
    icon: <AutoStoriesIcon />
  })

  return linkList
}

export default linkList
