'use client'

import { useState } from 'react'
import { styled } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import styles from './Sidebar.module.scss'
import linkList from './linkList'
import { IUser } from '@/interfaces/IUser'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

function Sidebar ({ user }: { user: IUser | null | undefined }) {
  const [open, setOpen] = useState(false)

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  return (
    <>
      <div className={styles.wrapper}>
        <IconButton
          color="inherit"
          className={styles.button}
          aria-label="open drawer"
          onClick={toggleDrawer(true)}
          edge="start"
          sx={[
            {
              mr: 2
            },
            open && { display: 'none' }
          ]}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <Drawer
        anchor={'right'}
        open={open}
        onClose={toggleDrawer(false)}
        className={styles.drawer}
      >
        <DrawerHeader className={styles.header}>
          <IconButton onClick={toggleDrawer(!open)}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {linkList(user).map((link, i) => {
            return (
              <ListItem disablePadding key={i}>
                <ListItemButton
                  component={'a'}
                  href={link.link}
                >
                  <ListItemIcon>
                    {link.icon}
                  </ListItemIcon>
                  <ListItemText primary={link.value} />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Drawer>
    </>
  )
}

export default Sidebar
